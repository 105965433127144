@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Roboto:wght@400;700&display=swap");

@responsive {
  .w-20percent {
    width: 20%;
  }

  .pl-20percent {
    padding-left: 20%;
  }
}

.montserrat {
  font-family: "Montserrat", sans-serif;
}

.roboto {
  font-family: "Roboto", sans-serif;
}

.react-select__control {
  @apply border;
  @apply border-gray-300;
  @apply block;
  border-radius: 0 !important;
  @apply w-full;
  @apply my-1;
  @apply p-1;
}

.ratio-1-1 {
  width: 100%;
  padding-top: 100%;
  position: relative;
}

.noreset p {
  margin: 1em 0;
}

.noreset blockquote,
.noreset figure {
  margin: 1em 40px;
}

.noreset h1 {
  font-size: 2em;
  font-weight: bold;
  margin: 0.67em 0;
}

.noreset h2 {
  font-size: 1.5em;
  font-weight: bold;
  margin: 0.83em 0;
}

.noreset h3 {
  font-size: 1.17em;
  font-weight: bold;
  margin: 1em 0;
}

.noreset h4 {
  font-size: 1em;
  font-weight: bold;
  margin: 1.33em 0;
}

.noreset h5 {
  font-size: 0.83em;
  font-weight: bold;
  margin: 1.67em 0;
}

.noreset h6 {
  font-size: 0.67em;
  font-weight: bold;
  margin: 2.33em 0;
}

.noreset ul,
.noreset menu {
  list-style-type: disc;
  margin: 1em 0;
  padding: 0 0 0 40px;
}

.noreset ol {
  list-style-type: decimal;
  margin: 1em 0;
  padding: 0 0 0 40px;
}

.loader-black {
  margin: 100px auto;
  font-size: 25px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load5 1.1s infinite ease;
  animation: load5 1.1s infinite ease;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.loader-white {
  margin: 100px auto;
  font-size: 25px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load6 1.1s infinite ease;
  animation: load6 1.1s infinite ease;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
@-webkit-keyframes load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #ffffff,
      1.8em -1.8em 0 0em rgba(50, 50, 50, 0.2),
      2.5em 0em 0 0em rgba(50, 50, 50, 0.2),
      1.75em 1.75em 0 0em rgba(50, 50, 50, 0.2),
      0em 2.5em 0 0em rgba(50, 50, 50, 0.2),
      -1.8em 1.8em 0 0em rgba(50, 50, 50, 0.2),
      -2.6em 0em 0 0em rgba(50, 50, 50, 0.5),
      -1.8em -1.8em 0 0em rgba(50, 50, 50, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(50, 50, 50, 0.7),
      1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(50, 50, 50, 0.2),
      1.75em 1.75em 0 0em rgba(50, 50, 50, 0.2),
      0em 2.5em 0 0em rgba(50, 50, 50, 0.2),
      -1.8em 1.8em 0 0em rgba(50, 50, 50, 0.2),
      -2.6em 0em 0 0em rgba(50, 50, 50, 0.2),
      -1.8em -1.8em 0 0em rgba(50, 50, 50, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(50, 50, 50, 0.5),
      1.8em -1.8em 0 0em rgba(50, 50, 50, 0.7), 2.5em 0em 0 0em #ffffff,
      1.75em 1.75em 0 0em rgba(50, 50, 50, 0.2),
      0em 2.5em 0 0em rgba(50, 50, 50, 0.2),
      -1.8em 1.8em 0 0em rgba(50, 50, 50, 0.2),
      -2.6em 0em 0 0em rgba(50, 50, 50, 0.2),
      -1.8em -1.8em 0 0em rgba(50, 50, 50, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(50, 50, 50, 0.2),
      1.8em -1.8em 0 0em rgba(50, 50, 50, 0.5),
      2.5em 0em 0 0em rgba(50, 50, 50, 0.7), 1.75em 1.75em 0 0em #ffffff,
      0em 2.5em 0 0em rgba(50, 50, 50, 0.2),
      -1.8em 1.8em 0 0em rgba(50, 50, 50, 0.2),
      -2.6em 0em 0 0em rgba(50, 50, 50, 0.2),
      -1.8em -1.8em 0 0em rgba(50, 50, 50, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(50, 50, 50, 0.2),
      1.8em -1.8em 0 0em rgba(50, 50, 50, 0.2),
      2.5em 0em 0 0em rgba(50, 50, 50, 0.5),
      1.75em 1.75em 0 0em rgba(50, 50, 50, 0.7), 0em 2.5em 0 0em #ffffff,
      -1.8em 1.8em 0 0em rgba(50, 50, 50, 0.2),
      -2.6em 0em 0 0em rgba(50, 50, 50, 0.2),
      -1.8em -1.8em 0 0em rgba(50, 50, 50, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(50, 50, 50, 0.2),
      1.8em -1.8em 0 0em rgba(50, 50, 50, 0.2),
      2.5em 0em 0 0em rgba(50, 50, 50, 0.2),
      1.75em 1.75em 0 0em rgba(50, 50, 50, 0.5),
      0em 2.5em 0 0em rgba(50, 50, 50, 0.7), -1.8em 1.8em 0 0em #ffffff,
      -2.6em 0em 0 0em rgba(50, 50, 50, 0.2),
      -1.8em -1.8em 0 0em rgba(50, 50, 50, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(50, 50, 50, 0.2),
      1.8em -1.8em 0 0em rgba(50, 50, 50, 0.2),
      2.5em 0em 0 0em rgba(50, 50, 50, 0.2),
      1.75em 1.75em 0 0em rgba(50, 50, 50, 0.2),
      0em 2.5em 0 0em rgba(50, 50, 50, 0.5),
      -1.8em 1.8em 0 0em rgba(50, 50, 50, 0.7), -2.6em 0em 0 0em #ffffff,
      -1.8em -1.8em 0 0em rgba(50, 50, 50, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(50, 50, 50, 0.2),
      1.8em -1.8em 0 0em rgba(50, 50, 50, 0.2),
      2.5em 0em 0 0em rgba(50, 50, 50, 0.2),
      1.75em 1.75em 0 0em rgba(50, 50, 50, 0.2),
      0em 2.5em 0 0em rgba(50, 50, 50, 0.2),
      -1.8em 1.8em 0 0em rgba(50, 50, 50, 0.5),
      -2.6em 0em 0 0em rgba(50, 50, 50, 0.7), -1.8em -1.8em 0 0em #ffffff;
  }
}
@keyframes load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #ffffff,
      1.8em -1.8em 0 0em rgba(50, 50, 50, 0.2),
      2.5em 0em 0 0em rgba(50, 50, 50, 0.2),
      1.75em 1.75em 0 0em rgba(50, 50, 50, 0.2),
      0em 2.5em 0 0em rgba(50, 50, 50, 0.2),
      -1.8em 1.8em 0 0em rgba(50, 50, 50, 0.2),
      -2.6em 0em 0 0em rgba(50, 50, 50, 0.5),
      -1.8em -1.8em 0 0em rgba(50, 50, 50, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(50, 50, 50, 0.7),
      1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(50, 50, 50, 0.2),
      1.75em 1.75em 0 0em rgba(50, 50, 50, 0.2),
      0em 2.5em 0 0em rgba(50, 50, 50, 0.2),
      -1.8em 1.8em 0 0em rgba(50, 50, 50, 0.2),
      -2.6em 0em 0 0em rgba(50, 50, 50, 0.2),
      -1.8em -1.8em 0 0em rgba(50, 50, 50, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(50, 50, 50, 0.5),
      1.8em -1.8em 0 0em rgba(50, 50, 50, 0.7), 2.5em 0em 0 0em #ffffff,
      1.75em 1.75em 0 0em rgba(50, 50, 50, 0.2),
      0em 2.5em 0 0em rgba(50, 50, 50, 0.2),
      -1.8em 1.8em 0 0em rgba(50, 50, 50, 0.2),
      -2.6em 0em 0 0em rgba(50, 50, 50, 0.2),
      -1.8em -1.8em 0 0em rgba(50, 50, 50, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(50, 50, 50, 0.2),
      1.8em -1.8em 0 0em rgba(50, 50, 50, 0.5),
      2.5em 0em 0 0em rgba(50, 50, 50, 0.7), 1.75em 1.75em 0 0em #ffffff,
      0em 2.5em 0 0em rgba(50, 50, 50, 0.2),
      -1.8em 1.8em 0 0em rgba(50, 50, 50, 0.2),
      -2.6em 0em 0 0em rgba(50, 50, 50, 0.2),
      -1.8em -1.8em 0 0em rgba(50, 50, 50, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(50, 50, 50, 0.2),
      1.8em -1.8em 0 0em rgba(50, 50, 50, 0.2),
      2.5em 0em 0 0em rgba(50, 50, 50, 0.5),
      1.75em 1.75em 0 0em rgba(50, 50, 50, 0.7), 0em 2.5em 0 0em #ffffff,
      -1.8em 1.8em 0 0em rgba(50, 50, 50, 0.2),
      -2.6em 0em 0 0em rgba(50, 50, 50, 0.2),
      -1.8em -1.8em 0 0em rgba(50, 50, 50, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(50, 50, 50, 0.2),
      1.8em -1.8em 0 0em rgba(50, 50, 50, 0.2),
      2.5em 0em 0 0em rgba(50, 50, 50, 0.2),
      1.75em 1.75em 0 0em rgba(50, 50, 50, 0.5),
      0em 2.5em 0 0em rgba(50, 50, 50, 0.7), -1.8em 1.8em 0 0em #ffffff,
      -2.6em 0em 0 0em rgba(50, 50, 50, 0.2),
      -1.8em -1.8em 0 0em rgba(50, 50, 50, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(50, 50, 50, 0.2),
      1.8em -1.8em 0 0em rgba(50, 50, 50, 0.2),
      2.5em 0em 0 0em rgba(50, 50, 50, 0.2),
      1.75em 1.75em 0 0em rgba(50, 50, 50, 0.2),
      0em 2.5em 0 0em rgba(50, 50, 50, 0.5),
      -1.8em 1.8em 0 0em rgba(50, 50, 50, 0.7), -2.6em 0em 0 0em #ffffff,
      -1.8em -1.8em 0 0em rgba(50, 50, 50, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(50, 50, 50, 0.2),
      1.8em -1.8em 0 0em rgba(50, 50, 50, 0.2),
      2.5em 0em 0 0em rgba(50, 50, 50, 0.2),
      1.75em 1.75em 0 0em rgba(50, 50, 50, 0.2),
      0em 2.5em 0 0em rgba(50, 50, 50, 0.2),
      -1.8em 1.8em 0 0em rgba(50, 50, 50, 0.5),
      -2.6em 0em 0 0em rgba(50, 50, 50, 0.7), -1.8em -1.8em 0 0em #ffffff;
  }
}

@-webkit-keyframes load6 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #ffffff,
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7),
      1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #ffffff,
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #ffffff,
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.5),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #ffffff,
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #ffffff,
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #ffffff,
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #ffffff;
  }
}
@keyframes load6 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #ffffff,
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7),
      1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #ffffff,
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #ffffff,
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.5),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #ffffff,
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #ffffff,
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #ffffff,
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #ffffff;
  }
}
